import Parse from 'parse';


export const getAllBookingBlogs = async (dispatch) => {
  const BookingBlog = Parse.Object.extend("BookingBlog");
  const query = new Parse.Query(BookingBlog);
  const newAllBlogs = await query.find();

  dispatch(setAllBlogs(newAllBlogs));

  return newAllBlogs;
};

export const getBookingBlogById = async (dispatch, id, mode, allBlogs) => {
  let blog;
  const BookingBlog = Parse.Object.extend("BookingBlog");
  if (allBlogs) {
    blog = allBlogs.find(item => item.id === id);
  } else {
    const query = new Parse.Query(BookingBlog);
    try {
      blog = await query.get(id);
    } catch (e) {
      if (mode === "edit")
        blog = new BookingBlog();
      else throw e;
    }
  }
  if (blog.get('html') && !blog.get("html").get('content'))
    await blog.get('html').fetch();

  dispatch(setBlog(blog))
  return blog;
}

const setAllBlogs = allBlogs => {
  return {
    type: "SET_ALL_BLOGS",
    allBlogs: allBlogs
  }
}

const setBlog = blog => {
  return {
    type: "SET_BLOG",
    blog: blog
  }
}

const blogReducer = (state = {
  allBlogs: null,
  blog: null
}, action) => {
  switch (action.type) {
    case "SET_BLOG":
      return {
        ...state,
        blog: action.blog
      }
    case "SET_ALL_BLOGS":
      return {
        ...state,
        allBlogs: action.allBlogs
      }
    default:
      return state;
  }
}
export default blogReducer;