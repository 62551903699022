import Parse from 'parse';


export const urlToObject = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], 'image.jpg', { type: "image/jpeg" });
  return file;
};

export const getAllPhotosById = async (dispatch, id) => {
  const PromotableR = Parse.Object.extend("PromotableR");
  const query = new Parse.Query(PromotableR);
  query.limit(10000);
  query.equalTo("albumsRID", { "__type": "Pointer", "className": "AlbumR", "objectId": id });
  query.doesNotExist("shouldBeShownToUploader");

  let allPhotos = await query.find();
  let coverPhoto = null;
  let foundBigWidth = false
  let file2 = null
  for (const photo of allPhotos) {

    var isOnSale = photo.get('isOnSale')
    if (isOnSale) {
      photo.src = photo.get('uploadedImageCompressedWater').url();
      photo.title = false

    }
    else {
      photo.src = photo.get('uploadedImageOriginal').url();
      photo.title = true
      //file2 = urlToObject(photo.get('uploadedImageOriginal').url())
      //photo.file = file2
    }
    photo.height = photo.get('imageHeight');
    photo.width = photo.get('imageWidth');

    if (photo.width > photo.height) {
      coverPhoto = photo
      foundBigWidth = true
    }
    else {
      if (foundBigWidth === false) {
        coverPhoto = photo
      }
    }

  }
  dispatch(setShareFile(file2))
  dispatch(setCoverPhoto(coverPhoto))
  dispatch(setAllPhotos(allPhotos))
  return allPhotos;
};

export const getAlbumRById = async (dispatch, id) => {
  const AlbumR = Parse.Object.extend("AlbumR");
  const query = new Parse.Query(AlbumR);
  const albumR = await query.get(id);
  await albumR.get('BookingForm').fetch();
  dispatch(setAlbumR(albumR));

  return albumR;
}

export const handleCurrency = (dispatch, albumR) => {
  if (albumR.get('currency') === "GBP")
    dispatch(setCurrency("£"));
}


// export const handleAddPhotos = (dispatch, mode, photo) => {
//   dispatch(addPhotos(mode, photo));
// }
// export const handleDeletePhotos = (dispatch, mode, photo) => {
//   dispatch(deletePhotos(mode, photo));
// }
// export const handleClearPhotos = (dispatch, mode) => {
//   dispatch(clearPhotos(mode));
// }
// export const handleAddMultiplePhotos = (dispatch, mode, photos) => {
//   dispatch(addMultiplePhotos(mode, photos));
// }

// export const handleSetBuyFullGallery = (dispatch, value) => {
//   dispatch(setBuyFullGallery(value));
// }


export const setAllPhotos = (allPhotos) => {
  return {
    type: "SET_ALL_PHOTOS",
    allPhotos: allPhotos
  }
}

export const setShareFile = (shareFile) => {
  return {
    type: "SET_SHARE_FILE",
    shareFile: shareFile
  }
}

export const setCoverPhoto = (coverPhoto) => {
  return {
    type: "SET_COVER_PHOTO",
    coverPhoto: coverPhoto
  }
}

export const setAlbumR = (albumR) => {
  return {
    type: "SET_ALBUMR",
    albumR: albumR
  }
}

export const addPhotos = (mode, photo) => {
  return {
    type: "ADD_PHOTOS",
    mode: mode,
    photo: photo
  }
}

export const changePhotos = (mode, photo) => {
  return {
    type: "CHANGE_PHOTOS",
    mode: mode,
    photo: photo
  }
}

export const sharePhotos = (mode, photo) => {
  return {
    type: "SHARE_PHOTOS",
    mode: mode,
    photo: photo
  }
}



export const deletePhotos = (mode, photo) => {
  return {
    type: "DELETE_PHOTOS",
    mode: mode,
    photo: photo
  }
}
export const clearPhotos = (mode) => {
  return {
    type: "CLEAR_PHOTOS",
    mode: mode
  }
}
export const addMultiplePhotos = (mode, photos) => {
  return {
    type: "ADD_MULTIPLE_PHOTOS",
    mode: mode,
    photos: photos
  }
}

export const setBuyFullGallery = (buyFullGallery) => {
  return {
    type: "SET_BUY_FULL_GALLERY",
    buyFullGallery: buyFullGallery
  }
}
export const toggleBuyFullGallery = (value) => {
  return {
    type: "TOGGLE_BUY_FULL_GALLERY",
    value: value
  }
}

export const setFeatureSession = (value) => {
  return {
    type: "SET_FEATURE_SESSION",
    value: value
  }
}

export const setCurrency = (currency) => {
  return {
    type: "SET_CURRENCY",
    currency: currency
  }
}

const galleryReducer = (state = {
  allPhotos: null,
  shareFile: null,
  coverPhoto: null,
  favPhotos: [],
  cartPhotos: [],
  sharedPhotos: [],
  buyFullGallery: [],
  featureSession: false,
  currency: "$"
}, action) => {
  switch (action.type) {
    case "SET_ALL_PHOTOS":
      return {
        ...state,
        allPhotos: action.allPhotos
      }
    case "SET_SHARE_FILE":
      return {
        ...state,
        allPhotos: action.shareFile
      }
    case "SET_COVER_PHOTO":
      return {
        ...state,
        coverPhoto: action.coverPhoto
      }
    case "SET_ALBUMR":
      return {
        ...state,
        albumR: action.albumR
      }
    case "ADD_PHOTOS":
      return {
        ...state,
        [action.mode]: state[action.mode].find(x => x.src === action.photo.src) ? [...state[action.mode]] : [...state[action.mode], action.photo]
      }
    case "CHANGE_PHOTOS":
      return {
        ...state,
        [action.mode]: state[action.mode].map(x => x.src === action.photo.oldSrc ? action.photo : x)
      }
    case "SHARE_PHOTOS":
      return {
        ...state,
        [action.mode]: state[action.mode].find(x => x.src === action.photo.src) ? [...state[action.mode]] : [...state[action.mode], action.photo]
      }
    case "DELETE_PHOTOS":
      return {
        ...state,
        [action.mode]: state[action.mode].filter(x => x.src !== action.photo.src)
      }
    case "CLEAR_PHOTOS":
      return {
        ...state,
        [action.mode]: []
      }
    case "ADD_MULTIPLE_PHOTOS":
      let result = [...state[action.mode]];
      action.photos.forEach(photo => {
        result = state[action.mode].find(x => x.src === photo.src) ? result : [...result, photo];
      });
      return {
        ...state,
        [action.mode]: result
      }
    case "SET_BUY_FULL_GALLERY":
      return {
        ...state,
        buyFullGallery: action.buyFullGallery
      }
    case "TOGGLE_BUY_FULL_GALLERY":
      return {
        ...state,
        buyFullGallery: action.value ? [...state.cartPhotos] : [],
        cartPhotos: action.value ? [...state.allPhotos] : [...state.buyFullGallery]
      }
    case "SET_FEATURE_SESSION":
      return {
        ...state,
        featureSession: action.value
      }
    case "SET_CURRENCY":
      return {
        ...state,
        currency: action.currency
      }
    default:
      return state;
  }
}
export default galleryReducer;