import React from "react";
import './Spinner.css';


const Spinner = (props) => {
  if (props.mode === "line")
    return (
      <div className="line DelayedSpinner">
        <div className="line-element"></div>
      </div>
    );

  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{ height: window.innerHeight }}
    >
      {props.text &&
        <>
          <h5 className="mb-3" style={{ fontWeight: 800 }}> {props.text}</h5>
        </>
      }


      {
        props.mode === "cube" &&
        <div className="sk-cube-grid">
          <div className="sk-cube sk-cube1"></div>
          <div className="sk-cube sk-cube2"></div>
          <div className="sk-cube sk-cube3"></div>
          <div className="sk-cube sk-cube4"></div>
          <div className="sk-cube sk-cube5"></div>
          <div className="sk-cube sk-cube6"></div>
          <div className="sk-cube sk-cube7"></div>
          <div className="sk-cube sk-cube8"></div>
          <div className="sk-cube sk-cube9"></div>
        </div>
      }

      {
        props.mode === "bounce" &&
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      }

    </div >
  );
}

export default Spinner;
