import React, { Suspense, lazy, useState } from "react";
import { Route, Switch } from "react-router-dom";

import Spinner from "./components/Common/Spinner";
import {
  useHandleGlutter,
  useHandleLanguage,
  useHandleCity,
  useAddArgs,
  useHandleRef,
  useHandlePin,
  useHandleEmail,
} from "./store/hooks";

const Homepage = lazy(() => import("./screens/Homepage"));
const Bookpage = lazy(() => import("./screens/Bookpage"));
const Photographerpage = lazy(() => import("./screens/Photographerpage"));
const Eventpage = lazy(() => import("./screens/Eventpage"));
const EventRefpage = lazy(() => import("./screens/EventRefpage"));
const Termspage = lazy(() => import("./screens/Termspage"));
const Sampleworkorderpage = lazy(() => import("./screens/Sampleworkorderpage"));
const Blogpage = lazy(() => import("./screens/Blogpage"));
const Articlepage = lazy(() => import("./screens/Articlepage"));
const Managepage = lazy(() => import("./screens/Managepage"));
const Gallerypage = lazy(() => import("./screens/Gallerypage"));
const Editingpage = lazy(() => import("./screens/Editingpage"));
const Faqpage = lazy(() => import("./screens/Faqpage"));
const Votepage = lazy(() => import("./screens/Votepage"));
const Joinpage = lazy(() => import("./screens/Joinpage"));
const Waitinglistpage = lazy(() => import("./screens/Waitinglistpage"));
const SelectLocationModal = lazy(() =>
  import("./components/Common/SelectLocationModal")
);

const App = () => {
  const [modalShow, setModalShow] = useState(false);
  const openModal = () => {
    setModalShow(true);
  };

  useHandleGlutter();
  useHandleLanguage();
  useHandleRef();
  useHandlePin();
  useHandleEmail();
  useHandleCity(openModal);
  useAddArgs();

  return (
    <Suspense fallback={<Spinner mode="bounce" />}>
      <Switch>
        <Route path="/gallery/:id" component={Gallerypage} />
        <Route path="/manage/:id" component={Managepage} />
        <Route path="/blog/:id" component={Articlepage} />
        <Route path="/blog/" component={Blogpage} />
        <Route path="/terms" component={Termspage} />
        <Route path="/sample-work-order" component={Sampleworkorderpage} />
        <Route path="/event/:id" component={Eventpage} />
        <Route path="/event/:id/:ref" component={EventRefpage} />
        <Route path="/book" component={Bookpage} />
        <Route path="/vote" component={Votepage} />
        <Route path="/photographer/:id" component={Photographerpage} />
        <Route path="/faq" component={Faqpage} />
        <Route path="/waitinglist/:id/:type" component={Waitinglistpage} />
        <Route path="/waitinglist/:id" component={Waitinglistpage} />
        <Route path="/join-us" component={Joinpage} />
        <Route path="/photo-editing-policy" component={Editingpage} />
        <Route path="/spotify" component={Homepage} />
        <Route path="/:id" component={Photographerpage} />
        <Route path="/" component={Homepage} />

        {/* <Redirect to="/not-found" /> */}
      </Switch>

      <SelectLocationModal
        onHide={() => setModalShow(false)}
        show={modalShow}
      />
    </Suspense>
  );
};

export default App;
