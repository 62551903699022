import React from 'react';
import Spinner from './Spinner';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });

    if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development')
      setTimeout(() => window.location = "/", 5 * 1000);
  }

  render() {
    if (this.state.hasError) {
      return <Spinner text="Something went wrong" mode="cube" />
    }

    return this.props.children;
  }
}

export default ErrorBoundary;