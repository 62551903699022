import EN from "../lang-en.json";
import RU from "../lang-ru.json";

export const setGlutter = glutter => {
  return {
    type: "SET_GLUTTER",
    glutter: glutter
  }
}

export const setLanguage = lang => {
  return {
    type: "SET_LANGUAGE",
    lang: lang,
    languagePage: lang === "en" ? EN : RU
  }
}

export const setLineLoading = lineLoading => {
  return {
    type: "SET_LINE_LOADING",
    lineLoading: lineLoading
  }
}

export const setCity = city => {
  return {
    type: "SET_CITY",
    city: city,
  }
}

export const setRef = ref => {
    return {
        type: "SET_REF",
        ref: ref,
    }
}

const uiReducer = (state = {
  glutter: 0,
  lang: "en",
  languagePage: EN,
  lineLoading: false,
  city: "London",
   ref: ""
}, action) => {
  switch (action.type) {
    case "SET_GLUTTER":
      return {
        ...state,
        glutter: action.glutter
      }
    case "SET_LANGUAGE":
      return {
        ...state,
        lang: action.lang,
        languagePage: action.languagePage
      }
    case "SET_CITY":
      return {
        ...state,
        city: action.city
      }
      case "SET_REF":
          return {
              ...state,
              ref: action.ref
          }
    case "SET_LINE_LOADING":
      return {
        ...state,
        lineLoading: action.lineLoading
      }
    default:
      return state;
  }
}
export default uiReducer;