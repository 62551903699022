export const setMessageSid = (msgSid) => {
  return {
    type: "SET_MESSAGE_ID",
    msgSid: msgSid,
  };
};

export const setMessageStatus = (msgStatus) => {
  return {
    type: "SET_MESSAGE_STATUS",
    msgStatus: msgStatus,
  };
};

const formReducer = (
  state = {
    msgSid: "",
    msgStatus: "",
  },
  action
) => {
  switch (action.type) {
    case "SET_MESSAGE_ID":
      return {
        ...state,
        msgSid: action.msgSid,
      };
    case "SET_MESSAGE_STATUS":
      return {
        ...state,
        msgStatus: action.msgStatus,
      };
    default:
      return state;
  }
};

export default formReducer;
