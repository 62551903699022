import { useEffect, useState, useMemo, unstable_useTransition, useRef } from "react";
import { hotjar } from 'react-hotjar';
import { useDispatch, useSelector } from 'react-redux'
import useSWR, { mutate } from 'swr'
import { setGlutter, setLanguage, setLineLoading, setCity, setRef } from './reducers/ui';
import { getEventDetailedById, getAllEventsFromGivenPlace, getAllEventsFromGivenPhotographer, getBookingFormById, getAllCities, getWaitingDetailedById, getWaitingDetailedCheck, getPhotographerDetailedById, getAllPhotographers, getAskPoll } from "./reducers/event"
import { getBookingBlogById, getAllBookingBlogs } from "./reducers/blog"
import { useHistory } from "react-router-dom";
import {
    getAllPhotosById, setAllPhotos, getAlbumRById, addPhotos, sharePhotos, deletePhotos, clearPhotos, addMultiplePhotos,
    setBuyFullGallery, toggleBuyFullGallery, setFeatureSession, handleCurrency, setAlbumR
} from "./reducers/gallery";



export const useArg = (name, search) => {
  const [arg, setArg] = useState();

  useEffect(() => {
    const match = window.decodeURI(search).match(new RegExp(`${name}=(.+)`));
    let result = match && match[1].split("&");
    if (result && result[1] && result[1][0] === " ") {
      result = result[0] + "&" + result[1];
    } else result = result && result[0];
    setArg(result);
  }, [name, search]);

  return arg;
}

export const useAddArgs = () => {
  const lang = useSelector(state => state.ui.lang);
  const city = useSelector(state => state.ui.city);
    const ref = useSelector(state => state.ui.ref);
    const photographer = useSelector(state => state.event.photographer);
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {

      if (!location.search.includes('city')&&(ref !== "" && ref !== null && !location.search.includes('ref')))
        history.replace({ ...location, search: `${location.search ? location.search + "&" : "?"}city=${city}&ref=${ref}` });
      else if (!location.search.includes('city')&&!(ref !== "" && ref !== null && !location.search.includes('ref')))
          history.replace({ ...location, search: `${location.search ? location.search + "&" : "?"}city=${city}` })


      if (lang !== "en" && !location.search.includes('lang'))
        history.replace({ ...location, search: `${location.search ? location.search + "&" : "?"}lang=${lang}` });

        if (ref !== "" && !location.search.includes('ref'))
            history.replace({ ...location, search: `${location.search ? location.search + "&" : "?"}ref=${ref}` });
    });
    return () => unlisten();
  }, [history, lang, city, ref]);
};

export const useHandleLanguage = () => {
  const dispatch = useDispatch();
  const arg = useArg("lang", window.location.search);

  useEffect(() => {
    if (arg)
      dispatch(setLanguage(arg));
  }, [dispatch, arg]);
}

export const useHandleRef = () => {
    const dispatch = useDispatch();
    const match = window.decodeURI(window.location.search).match(new RegExp(`${"ref"}=(.+)`));
    let result = match && match[1].split("&");
    if (result && result[1] && result[1][0] === " ") {
        result = result[0] + "&" + result[1];
    } else result = result && result[0];

    var arg = result ? result : window.localStorage.getItem("albumera_ref");
    if(arg===null){
        arg = ""
    }
    useEffect(() => {
        if (arg)
            dispatch(setRef(arg));
    }, [dispatch, arg]);
}


export const useHandleEmail = () => {
    const dispatch = useDispatch();
    const match = window.decodeURI(window.location.search).match(new RegExp(`${"email"}=(.+)`));
    let result = match && match[1].split("&");
    if (result && result[1] && result[1][0] === " ") {
        result = result[0] + "&" + result[1];
    } else result = result && result[0];

    var arg = result ? result : window.localStorage.getItem("albumera_pin");
    if(arg===null){
        arg = ""
    }
    useEffect(() => {
        if (arg)
            dispatch(setRef(arg));
    }, [dispatch, arg]);
}

export const useHandlePin = () => {
    const dispatch = useDispatch();
    const match = window.decodeURI(window.location.search).match(new RegExp(`${"pin"}=(.+)`));
    let result = match && match[1].split("&");
    if (result && result[1] && result[1][0] === " ") {
        result = result[0] + "&" + result[1];
    } else result = result && result[0];

    var arg = result ? result : window.localStorage.getItem("albumera_email");
    if(arg===null){
        arg = ""
    }
    useEffect(() => {
        if (arg)
            dispatch(setRef(arg));
    }, [dispatch, arg]);
}


export const useHandleCity = (openModal) => {
  const dispatch = useDispatch();
  const allCities = useSelector(state => state.event.allCities);

  useEffect(() => {
    const match = window.decodeURI(window.location.search).match(new RegExp(`${"city"}=(.+)`));
    let result = match && match[1].split("&");
    if (result && result[1] && result[1][0] === " ") {
      result = result[0] + "&" + result[1];
    } else result = result && result[0];

    let city = result ? result : window.localStorage.getItem("albumera_city");

    if(city){
        if(city!=="Istanbul"&&city!=="London"&&city!=="Online"){
            city = "London";
        }
    }
    else{
        city = "London";
    }



    if (city) {
        if(city!=="Istanbul"&&city!=="London"&&city!=="Online"){
            city = "London";
        }
        dispatch(setCity(city));
    }
    else {
      const path = window.location.pathname.split('/')[1];
      if (path === "manage" || path === "gallery") return;
      openModal();
    }
  }, [dispatch]);
}

export const useChangeCity = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return async (city) => {
      if(city!=="Istanbul"&&city!=="London"&&city!=="Online"){
          city = "London";
      }
    await dispatch(setCity(city));
    window.localStorage.setItem("albumera_city", city);
      const match = window.decodeURI(window.location.search).match(new RegExp(`${"ref"}=(.+)`));
      let result0= match && match[1].split("&");
      if (result0 && result0[1] && result0[1][0] === " ") {
          result0 = result0[0] + "&" + result0[1];
      } else result0 = result0 && result0[0];

      const ref = result0 ? result0 : window.localStorage.getItem("albumera_ref");
      if(ref===null){
          if(history.location.pathname.includes("/photographer/")){
              history.push(`/book?city=${city}`);
              //window.location.href=`${history.location.pathname}?city=${city}`;
          }
          else {
              /*
              history.push(`${history.location.pathname}?city=${city}`);
              */
              history.push(`/book?city=${city}`);
              //window.location.href=`${history.location.pathname}?city=${city}`;
          }
      }
      else {
          if(history.location.pathname.includes("/photographer/")){
              history.push(`/book?city=${city}&ref=${ref}`);
              //window.location.href=`${history.location.pathname}?city=${city}&ref=${ref}`;
          }
          else {
              /*
              history.push(`${history.location.pathname}?city=${city}&ref=${ref}`);
              */
              history.push(`/book?city=${city}&ref=${ref}`);
              //window.location.href=`${history.location.pathname}?city=${city}&ref=${ref}`;
          }
      }

  }
}

export const useChangeCityAndGoToBook = () => {
    const dispatch = useDispatch();
    const history = useHistory();


    return async (city) => {
        if(city!=="Istanbul"&&city!=="London"&&city!=="Online"){
            city = "London";
        }
        await dispatch(setCity(city));
        window.localStorage.setItem("albumera_city", city);
        const match = window.decodeURI(window.location.search).match(new RegExp(`${"ref"}=(.+)`));
        let result0= match && match[1].split("&");
        if (result0 && result0[1] && result0[1][0] === " ") {
            result0 = result0[0] + "&" + result0[1];
        } else result0 = result0 && result0[0];

        const ref = result0 ? result0 : window.localStorage.getItem("albumera_ref");
        if(ref===null){

            history.push(`/book?city=${city}`);
            window.location.href=`${history.location.pathname}?city=${city}`;
        }
        else {

            history.push(`/book?city=${city}&ref=${ref}`);
            window.location.href=`${history.location.pathname}?city=${city}&ref=${ref}`;
        }
    }
}
export const useNavbarSpacer = () => {
  const headerRef = useRef();
  const [space, setSpace] = useState(0);
  useEffect(() => {
    const windowResize = () => {
      const space = headerRef.current ? headerRef.current.getBoundingClientRect().height - 5 : 0;
      setSpace(space);
    }

    windowResize();
    window.addEventListener("resize", windowResize);

    return () => window.removeEventListener("resize", windowResize);
  }, []);

  return { headerRef, space };
}

export const useToggle = (length) => {
  const [opens, setOpens] = useState(new Array(length));

  const toggle = (index) => {
    let newOpens = new Array(length);
    newOpens[index] = !opens[index];
    setOpens(newOpens);
  }

  return [opens, toggle];
}

export const useHandleGlutter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const windowResize = () => {
      const glutter = window.innerWidth > 1400 ? (window.innerWidth - 1400) / 2 : 0;
      dispatch(setGlutter(glutter))
    }

    windowResize();
    window.addEventListener("resize", windowResize);
    return () => window.removeEventListener("resize", windowResize);
  }, [dispatch]);
}

export const useNavigate = () => {
  const allEvents = useSelector(state => state.event.allEvents);
    const allPhotographers = useSelector(state => state.event.photographer);
    const allWaitings = useSelector(state => state.event.allWaitings);
  const allBlogs = useSelector(state => state.blog.allBlogs);
  const city = useSelector(state => state.ui.city);
  const dispatch = useDispatch();
  const history = useHistory();

  const wrapFetcher = async (fetcher, arg, path, data) => {
    dispatch(setLineLoading(true));

    if (data) {
      dispatch(setLineLoading(false));
      history.push(path);
    }

    const result = await fetcher(...arg);

    if (!data) {
      dispatch(setLineLoading(false));
      history.push(path);
    }
    return result;
  };

  const [startTransition] = unstable_useTransition({ timeoutMs: 3000 });
  const wrapCallback = (callback, arg) => {
    startTransition(() => {
      callback(...arg);
    });
  }

  return ((path, ...arg) => {
    let handleNavigate;

    switch (path) {
      case "/blog/:id":
        handleNavigate = (id) => {
          let dummy = import('../screens/Articlepage');
          dummy = import('../components/Blogpage/Article');
          mutate(['BookingBlogById', id], () => wrapFetcher(getBookingBlogById, [dispatch, id, null, allBlogs], `/blog/${id}`));
        }
        break;
      case "/blog":
        handleNavigate = () => {
          const dummmy = import('../screens/Blogpage');
          mutate('AllBookingBlogs', () => wrapFetcher(getAllBookingBlogs, [dispatch], '/blog', allBlogs));
        }
        break;
      case "/book":
        handleNavigate = async () => {
          dispatch(setLineLoading(true));
          const dummy = await import("../screens/Bookpage");
          mutate(['AllEventsFromGivenPlace', city], () => wrapFetcher(getAllEventsFromGivenPlace, [dispatch, city], `/book`, allEvents));
        }
        break;
        case "/photographer/:id":
            handleNavigate = async (id) => {
                dispatch(setLineLoading(true));
                const dummy = await import("../screens/Photographerpage");
                mutate(['AllEventsFromGivenPhotographer', allPhotographers], () => wrapFetcher(getAllEventsFromGivenPhotographer, [dispatch, allPhotographers], `/photographer/${id}`, allEvents));
            }
            break;
      case "/event/:id":
        handleNavigate = async (id) => {
          dispatch(setLineLoading(true));
          const dummy = await import("../screens/Eventpage");
          mutate(['EventDetailedById', id], () => wrapFetcher(getEventDetailedById, [dispatch, id, allEvents], `/event/${id}`));
        }
        break;
        case "/event/:id/:ref":
            handleNavigate = async (id,ref) => {
                dispatch(setLineLoading(true));
                const dummy = await import("../screens/EventRefpage");
                mutate(['EventDetailedById', id], () => wrapFetcher(getEventDetailedById, [dispatch, id, allEvents], `/event/${id}/${ref}`));
            }
            break;

        case "/waitinglist/:id":
            handleNavigate = async (id) => {
                dispatch(setLineLoading(true));
                const dummy = await import("../screens/Waitinglistpage");
                mutate(['WaitingDetailedById', id], () => wrapFetcher(getEventDetailedById, [dispatch, id, allWaitings], `/waitinglist/${id}`));
            }
            break;
        case "/waitinglist/:id/:type":
            handleNavigate = async (id,type) => {
                dispatch(setLineLoading(true));
                const dummy = await import("../screens/Waitinglistpage");
                mutate(['WaitingDetailedById', id, type], () => wrapFetcher(getEventDetailedById, [dispatch, id, allWaitings, type], `/waitinglist/${id}/${type}`));
            }
            break;
      case "/":
        handleNavigate = async () => {
          dispatch(setLineLoading(true));
          const dummmy = await import('../screens/Homepage');
          history.push('/');
          dispatch(setLineLoading(false));
        }
        break;
      case "/terms":
        handleNavigate = async () => {
          dispatch(setLineLoading(true));
          const dummmy = await import('../screens/Termspage');
          history.push('/terms');
          dispatch(setLineLoading(false));
        }
        break;
      case "/faq":
        handleNavigate = async () => {
          dispatch(setLineLoading(true));
          const dummmy = await import('../screens/Faqpage');
          history.push('/faq');
          dispatch(setLineLoading(false));
        }
        break;
        case "/vote":
            handleNavigate = async () => {
                dispatch(setLineLoading(true));
                const dummmy = await import('../screens/Votepage');
                history.push('/vote');
                dispatch(setLineLoading(false));
            }
            break;
      default:
        console.log("DEFAULT");
    }

    wrapCallback(handleNavigate, arg);
  });
}

export const useIsChargedCancel = () => {
  const bookingForm = useSelector(state => state.event.bookingForm);

  const isChargedCancel = useMemo(() => {
    const differenceDays = (new Date(bookingForm && bookingForm.get('date')) - new Date()) / (1000 * 3600 * 24);
    return differenceDays <= 2;
  }, [bookingForm]);

  return isChargedCancel;
}

export const useAllEventsFromGivenPlace = (place) => {
  const dispatch = useDispatch();

  const fetcher = () => {
    return getAllEventsFromGivenPlace(dispatch, place);
  }

  useSWR(['AllEventsFromGivenPlace', place], fetcher, { suspense: true });
};


export const useAllEventsFromGivenPhotographer = (id) => {
    const dispatch = useDispatch();

    const fetcher = () => {
        return getAllEventsFromGivenPhotographer(dispatch, id);
    }

    useSWR(['AllEventsFromGivenPhotographer', id], fetcher, { suspense: true });
};



export const useEventDetailedById = (id) => {
  const dispatch = useDispatch();
  const allEvents = useSelector(state => state.event.allEvents);

  const fetcher = () => {
    return getEventDetailedById(dispatch, id, allEvents);
  }

  useSWR(['EventDetailedById', id], () => fetcher(), { suspense: true });
}



export const usePhotographerDetailedById = (id) => {
    const dispatch = useDispatch();
    const allPhotographers = useSelector(state => state.event.allPhotographers);

    const fetcher = () => {
        return getPhotographerDetailedById(dispatch, id, allPhotographers);
    }

    useSWR(['PhotographerDetailedById', id], () => fetcher(), { suspense: true });
}


export const useWaitingDetailedById = (id,type) => {
    const dispatch = useDispatch();
    const allWaitings = useSelector(state => state.event.allWaitings);

    const fetcher = () => {
        return getWaitingDetailedById(dispatch, id, allWaitings,type);
    }

    useSWR(['WaitingDetailedById', id], () => fetcher(), { suspense: true });
}


export const useWaitingDetailedCheck = (id, type) => {
    const dispatch = useDispatch();
    const allWaitings = useSelector(state => state.event.allWaitings);

    const fetcher = () => {
        return getWaitingDetailedCheck(dispatch, id, allWaitings,type);
    }

    useSWR(['WaitingDetailedCheck', id , type], () => fetcher(), { suspense: true });
}



export const useBookingFormById = (id) => {
  const dispatch = useDispatch();

  const fetcher = () => {
    return getBookingFormById(dispatch, id);
  }

  useSWR(['BookingFormById', id], () => fetcher(), { suspense: true });
}

export const useAllBookingBlogs = () => {
  const dispatch = useDispatch();

  const fetcher = () => {
    return getAllBookingBlogs(dispatch);
  }

  useSWR('AllBookingBlogs', fetcher, { suspense: true });
}

export const useBookingBlogById = (id, mode) => {
  const dispatch = useDispatch();
  const allBlogs = useSelector(state => state.blog.allBlogs);

  const fetcher = () => {
    return getBookingBlogById(dispatch, id, mode, allBlogs);
  }

  useSWR(['BookingBlogById', id, mode], fetcher, { suspense: true });
}

export const useAllPhotosById = (id) => {
  const dispatch = useDispatch();
  const allPhotos = useSelector(state => state.gallery.allPhotos);
  const albumR = useSelector(state => state.gallery.albumR);

  const fetcher = () => {
    return getAllPhotosById(dispatch, id);
  }

  const [entered, setEntered] = useState(false);
  useEffect(() => {
    if (allPhotos && !entered) {
      ["favPhotos", "cartPhotos", "sharedPhotos"].forEach((mode) => {
        dispatch(addMultiplePhotos(mode, allPhotos.filter(x => albumR.get(mode) && albumR.get(mode).includes(x.id))));
      });
      dispatch(setBuyFullGallery(allPhotos.filter(x => albumR.get('buyFullGallery') && albumR.get('buyFullGallery').includes(x.id))));
      dispatch(setFeatureSession(albumR.get('featureSession')))
      setEntered(true);
    }
  }, [allPhotos])


  // useEffect(() => {
  //   if (!allPhotos) return;

  //   const fetch = async () => {
  //     await new Promise((res) => {
  //       let final = [];
  //       allPhotos.forEach((photo, i) => {
  //         const img = document.createElement('img');
  //         img.src = `${photo.get('uploadedImageCompressedWater').url()}`;
  //         img.index = i;

  //         img.onload = (e) => {
  //           const target = e.target;
  //           photo.src = target.src;
  //           photo.width = target.naturalWidth;
  //           photo.height = target.naturalHeight;
  //           final[target.index] = { ...photo, src: target.src, width: target.naturalWidth, height: target.naturalHeight };
  //         }

  //         const interval = setInterval(() => {
  //           if (final.length === allPhotos.length && !final.includes(undefined)) {
  //             res(final)
  //             clearInterval(interval);
  //           }
  //         }, 200);
  //       });
  //     });
  //     dispatch(setAllPhotos([...allPhotos]));
  //   }
  //   fetch();
  // }, [allPhotos]);

  useSWR(['AllPhotosById', id], fetcher, { suspense: true, revalidateOnFocus: false });
}


export const useAlbumRById = (id) => {
  const dispatch = useDispatch();

  const fetcher = () => {
    return getAlbumRById(dispatch, id);
  }

  useSWR(['AlbumRById', id], fetcher, { suspense: true });
}

export const useHandleAddPhotos = () => {
  const dispatch = useDispatch();
  const albumR = useSelector(state => state.gallery.albumR);

  return (mode, photo) => {
    albumR.addUnique(mode, photo.id);
    albumR.save();
    dispatch(addPhotos(mode, photo));
  }
}

export const useHandleSharePhotos = () => {
    const dispatch = useDispatch();
    const albumR = useSelector(state => state.gallery.albumR);

    return (mode, photo) => {
        albumR.addUnique(mode, photo.id);
        albumR.save();
        dispatch(sharePhotos(mode, photo));
    }
}





export const isMobileOrTabletDevice = () => {
    let check = false;

    (function(a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
};

export const useMobileDevice = () => {
    const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

    useEffect(() => {
        setIsMobileOrTablet(isMobileOrTabletDevice());
    });

    return [isMobileOrTablet];
};

export const useHandleDeletePhotos = () => {
  const dispatch = useDispatch();
  const albumR = useSelector(state => state.gallery.albumR);

  return (mode, photo) => {
    albumR.remove(mode, photo.id);
    albumR.save();
    dispatch(deletePhotos(mode, photo));
  }
}



export const useHandleClearPhotos = () => {
  const dispatch = useDispatch();
  const albumR = useSelector(state => state.gallery.albumR);

  return (mode) => {
    albumR.set(mode, []);
    albumR.save();
    dispatch(clearPhotos(mode));
  }
}

export const useHandleAddMultiplePhotos = () => {
  const dispatch = useDispatch();
  const albumR = useSelector(state => state.gallery.albumR);

  return (mode, photos) => {
    photos.forEach(photo => {
      albumR.addUnique(mode, photo.id);
      albumR.save();
    })
    dispatch(addMultiplePhotos(mode, photos));
  }
}


export const useHandleChangePhoto = () => {
    const dispatch = useDispatch();
    const albumR = useSelector(state => state.gallery.albumR);

    return (mode, photos) => {
        photos.forEach(photo => {
            albumR.addUnique(mode, photo.id);
            albumR.save();
        })
        dispatch(addMultiplePhotos(mode, photos));
    }
}


export const useHandleSetBuyFullGallery = () => {
  const dispatch = useDispatch();
  const albumR = useSelector(state => state.gallery.albumR);
  const cartPhotos = useSelector(state => state.gallery.cartPhotos);
  const allPhotos = useSelector(state => state.gallery.allPhotos);
  const buyFullGallery = useSelector(state => state.gallery.buyFullGallery);

  return (value) => {
    if (value) {
      albumR.set("buyFullGallery", cartPhotos.map(photo => photo.id));
      albumR.set("cartPhotos", allPhotos.map(photo => photo.id));
    } else {
      albumR.set("buyFullGallery", []);
      albumR.set("cartPhotos", buyFullGallery.map(photo => photo.id));
    }
    albumR.save();
    dispatch(toggleBuyFullGallery(value));
  }
}

export const useHandleFeatureSession = () => {
  const dispatch = useDispatch();
  const albumR = useSelector(state => state.gallery.albumR);

  return (value) => {
    albumR.set('featureSession', value);
    albumR.save();
    dispatch(setFeatureSession(value));
  }
}

export const useAllCities = () => {
  const dispatch = useDispatch();

  const fetcher = () => {
    return getAllCities(dispatch);
  }

  useSWR('AllCities', fetcher, { suspense: true });
}

export const useAllPhotographers = (id) => {


    const dispatch = useDispatch();
    const allPhotographers = useSelector(state => state.event.allPhotographers);

    const fetcher = () => {
        return getAllPhotographers(dispatch, id);
    }

    useSWR(['AllPhotographers', id], () => fetcher(), { suspense: true });
}


export const useAskPoll = (id) => {


    const dispatch = useDispatch();
    const askPoll = useSelector(state => state.event.askPoll);
    let hj = hotjar;
    useEffect(() => {
        window.scrollTo(0, 0);
        window.hj=window.hj||function(){(hj.q=hj.q||[]).push(arguments)};
        window.hj('trigger', 'notbooking_popup');
    }, [hj]);
    const fetcher = () => {
        return getAskPoll(dispatch);
    }

    useSWR(['AskPoll', id], () => fetcher(), { suspense: true });
}


export const useCurrency = () => {
  const dispatch = useDispatch();
  const albumR = useSelector(state => state.gallery.albumR);

  useEffect(() => {
    if (albumR)
      handleCurrency(dispatch, albumR);
  }, [albumR, dispatch]);
}



// /* HELPER FUNCTION */
// const useConcurrency = (fetcher, callback, key, data) => {
//   const dispatch = useDispatch();
//   const history = useHistory();

//   const wrapFetcher = async (path, ...arg) => {
//     dispatch(setLineLoading(true));

//     if (data) {
//       dispatch(setLineLoading(false));
//       history.push(path);
//     }

//     const result = await fetcher(...arg);

//     if (!data) {
//       dispatch(setLineLoading(false));
//       history.push(path);
//     }
//     return result;
//   };

//   const [startTransition] = unstable_useTransition({ timeoutMs: 3000 });
//   const wrapCallback = (arg) => {
//     startTransition(() => {
//       callback(arg);
//     });
//   }

//   useSWR(!callback && key, fetcher, { suspense: true });
//   if (callback) return { callback: wrapCallback, fetcher: wrapFetcher };
// }
// /* END OF HELPER FUNCTION */
