import Parse from 'parse';

export const getAllEventsFromGivenPlace = async (dispatch, place) => {
  const BookingEventInfo = Parse.Object.extend("BookingEventInfo");
  const query = new Parse.Query(BookingEventInfo);
  // city!=="London && place!=="London" should be together
  if(place!=="Istanbul"&&place!=="London"&&place!=="Online"){
      place = "London";
  }
  query.equalTo("place", place);

  const allEvents = await query.find();

  for (const event of allEvents) {
      event.availableDates = event.get('availableDates').filter(date => (new Date(date)).getTime() > ((new Date()).getTime()-(48 * 60 * 60 * 1000)))

  }

  dispatch(setAllEvents(allEvents))
  return allEvents;
}


export const getAllEventsFromGivenPhotographer = async (dispatch, id) => {
    const BookingEventInfo = Parse.Object.extend("BookingEventInfo");
    const query = new Parse.Query(BookingEventInfo);
    query.equalTo("photographerUsername", id);

    const allEvents = await query.find();


    for (const event of allEvents) {

        event.availableDates = event.get('availableDates').filter(date => (new Date(date)).getTime() > ((new Date()).getTime()-(48 * 60 * 60 * 1000)))

    }

    dispatch(setAllEvents(allEvents))
    return allEvents;
}

export const getEventDetailedById = async (dispatch, id, allEvents) => {
  let event;

  if (allEvents)
    event = allEvents.find(item => item.id === id);
  else {
    const BookingEventInfo = Parse.Object.extend("BookingEventInfo");
    const query = new Parse.Query(BookingEventInfo);
    event = await query.get(id);
    event.availableDates = event.get('availableDates').filter(date => (new Date(date)).getTime() > ((new Date()).getTime()-(48 * 60 * 60 * 1000)));
  }

  dispatch(setEvent(event));

  return event;
};


export const getPhotographerDetailedById = async (dispatch, id, allPhotographers) => {
    let photographer;

    if (allPhotographers)
        photographer = allPhotographers.find(item => item.id === id);
    else {
        const BookingEventInfo = Parse.Object.extend("ProR");
        const query = new Parse.Query(BookingEventInfo);
        query.equalTo("username",id);
        let photographers = await query.find();
        photographer = photographers[0];
        photographer.fullName = photographer.get('fullName');
        photographer.id = photographer.get("username")
    }

    dispatch(setAllPhotographers(photographer));

    return photographer;
};


export const getAskPoll = async (dispatch) => {


    dispatch(setAskPoll(true));

    return true;
};



export const getWaitingDetailedById = async (dispatch, id, allWaitings,type) => {
    let waiting;

    if (allWaitings)
        waiting = allWaitings.find(item => item.email === id);
    else {
        const WaitingList2 = Parse.Object.extend("WaitingList");
        const query2 = new Parse.Query(WaitingList2);
        query2.descending('points');
        query2.addAscending('createdAt');
        const results2 = await query2.find();
        let dict = {};
        let order = {};
        for(let gg=0;gg<results2.length;gg++){
            dict[results2[gg].id] = gg + 1 ;
            order[gg+1] = results2[gg];
        }

        const WaitingList = Parse.Object.extend("WaitingList");
        const query = new Parse.Query(WaitingList);
        query.equalTo("email",id);
        const results = await query.find(id);
        if(results.length>0) {
            let currentPoints = order[dict[results[0].id]].get("points");
            let whatIfPoints = currentPoints + 1;
            let jumpTimes = 0;
            for (let ll = dict[results[0].id] - 1; ll > 0; ll--) {
                if (order[ll].get("points") < whatIfPoints) {
                    jumpTimes = jumpTimes + 1;
                }
                else {
                    break;
                }

            }
            waiting = results[0];
            waiting.points = waiting.get('points');
            waiting.verified = waiting.get('verified');
            waiting.referrer = waiting.get('referrer');
            waiting.referrerExist = false;
            if(waiting.referrer!=="NOREF"){
                waiting.referrerExist = true;
            }
            waiting.email = waiting.get('email');
            waiting.pin = waiting.get('pin');
            waiting.verified = waiting.get('verified');
            waiting.count = dict[waiting.id] + 18;
            waiting.nextPlace = dict[waiting.id] + 18 - jumpTimes;

            if (waiting.verified) {

            }
            else {
                if (type === undefined) {

                }
                else {
                    if (type === waiting.pin) {
                        waiting.set("verified", true);
                        waiting.save();
                        if(waiting.referrerExist){
                            const WaitingList = Parse.Object.extend("WaitingList");
                            const query = new Parse.Query(WaitingList);
                            query.equalTo("email",""+waiting.referrer);
                            const results = await query.find(""+waiting.referrer);
                            if(results.length>0) {
                                order[dict[results[0].id]].increment("points");
                                order[dict[results[0].id]].save();
                                const response2 = await fetch('https://contribute.reminis.app/index.php/up/sendprioritylistmail', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded',
                                    },
                                    body: `email=${allWaitings.get("email")}&place=${waiting.count}&friend=${waiting.referrer}`
                                });

                            }
                        }
                    }
                    else {
                        waiting.set("verified", false);
                        waiting.save();
                    }
                }
            }
        }

    }

    dispatch(setAllWaitings(waiting));

    return waiting;
};

export const getWaitingDetailedCheck = async (dispatch, id, allWaitings,type) => {

    let waiting;

        const WaitingList = Parse.Object.extend("WaitingList");
        const query = new Parse.Query(WaitingList);
        query.equalTo("email",id);
        const results = await query.find(id);
        waiting = results[0];
        waiting.points = waiting.get('points');
        waiting.verified = waiting.get('verified');
        waiting.referrer = waiting.get('referrer');
        waiting.email = waiting.get('email');
        waiting.pin = waiting.get('pin');
        if(""+waiting.pin===""+type){
            return true;
        }
        else{
            return false;
        }







};

export const getBookingFormById = async (dispatch, id) => {
  const BookingForm = Parse.Object.extend("BookingForm");
  const query = new Parse.Query(BookingForm);

  const bookingForm = await query.get(id);

  dispatch(setBookingForm(bookingForm));
  return bookingForm;
};

export const getAllCities = async (dispatch) => {
  const BookingCity = Parse.Object.extend("BookingCity");
  const query = new Parse.Query(BookingCity);
  const allCities = await query.find();

  dispatch(setAllCities(allCities));

  return allCities;
}

export const getAllPhotographers = async (dispatch, id) => {
    const Photographer = Parse.Object.extend("ProR");
    const query = new Parse.Query(Photographer);
    query.equalTo("username",id);
    const allPhotographers = await query.find();


    dispatch(setAllPhotographers(allPhotographers));

    return allPhotographers;
}


const setAllEvents = allEvents => {
  return {
    type: "SET_ALL_EVENTS",
    allEvents: allEvents
  }
}


const setAllWaitings = allWaitings => {
    return {
        type: "SET_ALL_WAITINGS",
        allWaitings: allWaitings
    }
}

const setEvent = event => {
  return {
    type: "SET_EVENT",
    event: event
  }
}


const setPhotographer = photographer => {
    return {
        type: "SET_PHOTOGRAPHER",
        photographer: photographer
    }
}


const setBookingForm = bookingForm => {
  return {
    type: "SET_BOOKING_FORM",
    bookingForm: bookingForm
  }
}

const setAllCities = allCities => {
  return {
    type: "SET_ALL_CITIES",
    allCities: allCities
  }
}
const setAllPhotographers = allPhotographers => {
    return {
        type: "SET_ALL_PHOTOGRAPHERS",
        allPhotographers: allPhotographers
    }
}
const setAskPoll = askPoll => {
    return {
        type: "SET_ASK_POLL",
        askPoll: askPoll,
        meta: {
            hotjar: {
                trigger: 'notbooking_popup'
            }
        }
    }
}
const eventReducer = (state = {
  allEvents: null,
  event: null,
  bookingForm: null,
  allCities: null,
    allWaitings: null,
    allPhotographers: null,
  availableDates: [],
    askPoll: false
}, action) => {
  switch (action.type) {
    case "SET_ALL_EVENTS":
      return {
        ...state,
        allEvents: action.allEvents
      }
      case "SET_ASK_POLL":
          return {
              ...state,
              askPoll: action.askPoll
          }
      case "SET_ALL_PHOTOGRAPHERS":
          return {
              ...state,
              allPhotographers: action.allPhotographers
          }
      case "SET_PHOTOGRAPHER":
          return {
              ...state,
              allPhotographers: action.allPhotographers
          }
      case "SET_ALL_WAITINGS":
          return {
              ...state,
              allWaitings: action.allWaitings
          }
    case "SET_EVENT":
      return {
        ...state,
        event: action.event
      }
    case "SET_BOOKING_FORM":
      return {
        ...state,
        bookingForm: action.bookingForm
      }
    case "SET_ALL_CITIES":
      return {
        ...state,
        allCities: action.allCities
      }
    default:
      return state;
  }
}
export default eventReducer;
