import { createStore, combineReducers, applyMiddleware } from "redux";
import logger from "redux-logger";
import uiReducer from "./reducers/ui";
import eventReducer from "./reducers/event";
import blogReducer from "./reducers/blog";
import galleryReducer from "./reducers/gallery";
import hotjarMiddleware from "redux-hotjar-trigger";
import formReducer from "./reducers/form";

/*
const pollTriggerMiddleware = hotjarMiddleware({
    hjid: '2245935',
    hjsv: '6'
});

*/

// CONFIGURE
const rootReducer = combineReducers({
  ui: uiReducer,
  event: eventReducer,
  blog: blogReducer,
  gallery: galleryReducer,
  form: formReducer,
});

const configureStore = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    //return createStore(rootReducer, applyMiddleware(pollTriggerMiddleware));
    return createStore(rootReducer);
  else return createStore(rootReducer);
  //return createStore(rootReducer, applyMiddleware(pollTriggerMiddleware));
};

export default configureStore;
